import { images } from "../shared/Images.js";

function Showcase() {
  return (
    <section className="showcase">
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div
            className="col-lg-6 order-lg-2 text-white showcase-img"
            style={{ backgroundImage: `url(${images.showcase.app})` }}
          ></div>
          <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            <h2>Responsive Design</h2>
            <p className="lead mb-0">
              The design of our product allows to use it on any device - it will
              always look stunning. We pay a lot of attention to web design of
              our clients’ projects to make their startups successful.
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div
            className="col-lg-6 text-white showcase-img"
            style={{ backgroundImage: `url(${images.showcase.dev})` }}
          ></div>
          <div className="col-lg-6 my-auto showcase-text">
            <h2>Consulting Services</h2>
            <p className="lead mb-0">
              We bring a deep industry expertise and the latest software
              development advancements to deliver solid custom solutions.
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div
            className="col-lg-6 order-lg-2 text-white showcase-img"
            style={{ backgroundImage: `url(${images.showcase.sms})` }}
          ></div>
          <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            <h2>Instant Customer Communication</h2>
            <p className="lead mb-0">
              All in one place powered by technology. Make every message an
              opportunity. Use our SMS service to instantly communicate with
              your customers by providing them a special treat on customer
              service or products currently on promotion.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Showcase;
