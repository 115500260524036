import { logo } from "../shared/Images.js";
import { Link } from "react-router-dom";

function Header() {
  return (
    <nav className="navbar navbar-light bg-light static-top">
      <div className="container nav-wrapper">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo" className="brand-logo" />
        </Link>
        <div>
          <Link className="pr-4" to="/contact">
            Contact Us
          </Link>
          <a
            className="btn btn-primary"
            target="_blank"
            href="https://invoicing.gauweng.co.za"
          >
            Create Invoices Free
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Header;
