import Services from "../components/Services.js";
import Showcase from "../components/Showcase.js";
import Testimony from "../components/Testimony.js";
function Home() {
  return (
    <>
      <Services />
      <Showcase />
      <Testimony />
    </>
  );
}

export default Home;
