import { images } from "../shared/Images.js";

function Testimony() {
  return (
    <section className="testimonials text-center bg-light">
      <div className="container">
        <h2 className="mb-5">Who we work with</h2>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid mb-3"
                src={images.client.arknote}
                alt="arknote"
              />
              {/* <h5>
                Arknote <br />
                <small>Powered by Info Verge</small>
              </h5> */}
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid mb-3"
                src={images.client.mendiGroup}
                alt="mendi-group"
              />
              {/* <h5>Mendi Group</h5> */}
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid mb-3"
                src={images.client.neroco}
                alt="neroco"
              />
              {/* <h5>Nero &amp; Co.</h5> */}
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid mb-3"
                src={images.client.tridentSALogo}
                alt="info-verge"
              />
              {/* <h5>Mendi Trident</h5> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimony;
