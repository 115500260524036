import React, { useState } from "react";
import { SendFormData } from "../shared/Server-fuctions.js";

function Contact() {
  const [serverMsg, setServerMsg] = useState("");
  const formReset = {
    name: "",
    email: "",
    telephone: "",
    comments: "",
  };
  const [form, setState] = useState(formReset);

  const updateField = (e) => {
    setServerMsg("");
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.name !== "" && form.email !== "" && form.telephone !== "") {
      SendFormData(form, "../backend/contact-us.php").then((data) => {
        if (data.status === "success") {
          setState(formReset);
        }
        setServerMsg(data.message);
        setTimeout(() => {
          setServerMsg("");
        }, 3000);
      });
    } else {
      setServerMsg("Please fill in all fields marked with *");
    }
  };

  return (
    <div className="container">
      <div className="row py-4">
        <div className="col-12">
          <h3 className="h3 main-header">Contact Us</h3>

          <div className="mb-3">
            1442 Crocosmia Street
            <br />
            Centurion
            <br />
            0157
            <br />
            <br />
            Tel:&nbsp;<a href="tel:+2781 505 2318">+2781 505 2318</a>
            <br />
            Email:&nbsp;
            <a href="mailto:info@servicenowit.co.za">info@servicenowit.co.za</a>
            <br />
            Free Tool:&nbsp;
            <a href="https://invoicing.gauweng.co.za/">Invoicing System</a>
          </div>

          {serverMsg !== "" && (
            <div className="invalid-input font-weight-bold py-2">
              <small>{serverMsg}</small>
            </div>
          )}

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mb-4">
                Please use this contact us form to spark a conversation.
              </div>
              <form onSubmit={handleSubmit} method="POST">
                <div className="form-group">
                  <label htmlFor="input-1st-name" className="font-weight-bold">
                    Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control col-sm-8 "
                    id="input-1st-name"
                    name="name"
                    aria-describedby="firstName"
                    placeholder="Name"
                    value={form.name}
                    onChange={updateField}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-email" className="font-weight-bold">
                    Email Address<sup>*</sup>
                  </label>
                  <input
                    type="email"
                    className="form-control col-sm-8"
                    id="input-email"
                    name="email"
                    aria-describedby="emailAddress"
                    placeholder="Email address"
                    value={form.email}
                    onChange={updateField}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-tel" className="font-weight-bold">
                    Contact Number<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control col-sm-8"
                    id="input-tel"
                    name="telephone"
                    aria-describedby="telephone"
                    placeholder="Enter contact number"
                    value={form.telephone}
                    onChange={updateField}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-comments" className="font-weight-bold">
                    Your message
                  </label>
                  <textarea
                    className="form-control col-sm-8"
                    id="input-comments"
                    name="comments"
                    rows="3"
                    value={form.comments}
                    onChange={updateField}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary px-5 bg-green text-white"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
