import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import "./App.css";
import Banner from "./components/Banner";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Banner />
        <Switch>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
