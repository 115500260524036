function Services() {
  return (
    <section className="services bg-light text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="service-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <i className="fa fa-terminal text-primary"></i>
              <h3>Be Online</h3>
              <p className="lead mb-0">
                Let us improve an online presence for your business. With our
                unique delivery approach, we are able to provide wide range of
                web-solutions from a basic website, a CMS (Content Management
                System) or an e-Commerce (Online store).
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="service-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <i className="fa fa-laptop text-primary"></i>
              <h3>Consult</h3>
              <p className="lead mb-0">
                Perhaps you do not have the relevant skills in-house, or maybe
                your software development team is occupied on other projects. We
                can provide an ongoing and dedicated support to ensure peace of
                mind.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="service-item mx-auto mb-0 mb-lg-3">
              <i className="fa fa-mobile text-primary"></i>
              <h3>Communicate</h3>
              <p className="lead mb-0">
                Make every message an opportunity. Using our SMS service we
                guarantee you an instant communication with your customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
