import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { SendFormData } from "../shared/Server-fuctions.js";

function Banner() {
  const location = useLocation();
  const formReset = { email: "" };
  const [form, setState] = useState(formReset);
  const [serverMsg, setServerMsg] = useState("");

  const updateField = (e) => {
    setServerMsg("");
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.email !== "") {
      setServerMsg("");
      SendFormData(form, "../backend/request-call-back.php").then((data) => {
        if (data.status === "success") {
          setState(formReset);
        }
        setServerMsg(data.message);
        setTimeout(() => {
          setServerMsg("");
        }, 3000);
      });
    } else {
      setServerMsg("Please enter a valid email address.");
    }
  };

  return (
    <header className="banner text-white text-center">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <h1 className="text-uppercase">Inspired Business Solutions</h1>
            <div className="mb-5 font-weight-bold">
              Our mission is simple, we want to help your business achieve the
              greatest possible success by providing the valuable services you
              need at a reasonable cost. We believe all businesses small and
              large should have access to an online presence.
            </div>
          </div>
          {location.pathname === "/" && (
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <form onSubmit={handleSubmit} method="POST">
                <div className="form-row">
                  <div className="col-12 col-md-9 mb-2 mb-md-0">
                    <input
                      value={form.email}
                      onChange={updateField}
                      name="email"
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Enter your email..."
                    />
                    {serverMsg !== "" && (
                      <div className="invalid-input font-weight-bold py-4">
                        <small>{serverMsg}</small>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-lg btn-primary"
                    >
                      Let's Talk!
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Banner;
