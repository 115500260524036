import srcLogo from "../assets/logo.png";
import srcMendiLogo from "../assets/mendi-logo-black.png";
import srcNeroLogo from "../assets/neroandco-logo.png";
import srcArkNoteLogo from "../assets/arknote.png";
import srcTridentSALogo from "../assets/trident-logo.png";

import srcShowcaseApp from "../assets/bg-showcase-4.png";
import srcShowcaseDev from "../assets/bg-showcase-5.jpg";
import srcShowcaseSms from "../assets/bg-showcase-3.jpg";

export const logo = srcLogo;

export const images = {
  client: {
    mendiGroup: srcMendiLogo,
    arknote: srcArkNoteLogo,
    neroco: srcNeroLogo,
    tridentSALogo: srcTridentSALogo,
  },
  showcase: {
    app: srcShowcaseApp,
    dev: srcShowcaseDev,
    sms: srcShowcaseSms,
  },
};
